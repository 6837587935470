<template>
  <v-card class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد فایل جدید
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="
          $router.replace({
            path: destinationRoute,
            query: $route.query,
          })
        "
      >
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />
        <multi-input
          class="mt-4"
          v-model="model.links"
          :schema="linksSchema"
          title="فایل ها"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn
            class="mx-4"
            dark
            color="success"
            :loading="loading"
            type="submit"
          >
            ایجاد فایل جدید
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import VFormBase from 'vuetify-form-base';
import MultiInput from '@/components/MultiInput.vue';
import { Constants } from '@/constants/GlobalConstants';
import MyAxios from '@/constants/MyAxios';
import { formPostPrepare } from '@/constants/PanelFormHandler';
import moment from 'jalali-moment';
export default {
  components: {
    VFormBase,
    MultiInput,
  },
  data() {
    return {
      rules: Constants.rules,
      destinationRoute: this.$route.fullPath.slice(
        0,
        this.$route.fullPath.indexOf('create') - 1
      ),
      loading: false,
      model: {
        name: '',
        slug: '',
        author_id: 0,
        categories: [],
        media_id: '',
        long_desc: '',
        status: '',
        software_id: '',
        links: [],
        demo: '',
        price: '',
        offer_price: '',
        end_date_offer: '',
        status_desc: '',
        features: [],
        tags: [],
        is_permanent_offer: false,
        orientation: 0,
      },
      schema: {
        name: {
          type: 'CustomInput',
          label: 'نام فایل',
          rules: [Constants.rules.required],
          required: true,
        },
        author_id: {
          type: 'CustomInput',
          label: 'کاربر قرار دهنده',
          inputType: 'autocomplete',
          items: [],
          rules: [Constants.rules.required],
          itemValue: 'id',
          itemText: 'value',
          loading: true,
        },
        long_desc: {
          type: 'MyTinyMCE',
          label: 'توضیحات',
          rules: [Constants.rules.required],
          required: true,
          col: { cols: 12 },
        },
        media_id: {
          type: 'MyFilePond',
          title: 'تصویر پیشفرض',
          rules: [Constants.rules.required],
          // imageMaxWidth: 300,
          required: true,
        },
        demo: {
          type: 'MyFilePond',
          title: 'اسکرین شات از پروژه',
          // fileTypes: "video/mp4",
          // maxFileSize: "12MB",
          rules: [Constants.rules.required],
          required: true,
        },

        software_id: {
          type: 'CustomInput',
          label: 'دسته بندی',
          inputType: 'autocomplete',
          items: [],
          itemValue: 'id',
          itemText: 'name',
          rules: [Constants.rules.required],
          loading: true,
        },

        features: {
          type: 'CustomInput',
          label: 'ویژگی ها',
          inputType: 'autocomplete',
          items: [],
          itemValue: 'id',
          itemText: 'title',
          multiple: true,
          loading: true,
          required: true,
        },
        orientation: {
          type: 'CustomInput',
          label: 'جهت',
          inputType: 'select',
          items: [
            { id: '0', value: 'افقی' },
            { id: '1', value: 'عمودی' },
            { id: '2', value: 'مربع' },
          ],
          itemValue: 'id',
          itemText: 'value',
          rules: [Constants.rules.required],
          required: true,
        },
        tags: {
          type: 'MyTagSelector',
          label: 'تگ ها',
          rules: [Constants.rules.array_max(4)],
        },
        price: {
          type: 'text-price-field',
          label: 'قیمت',
          rules: [Constants.rules.required_price, Constants.rules.numeric],
          persistentHint: true,
          hint: 'برای فایل رایگان این مقدار را 0 بگذارید.',
          required: true,
          suffix: 'تومان',
        },
        offer_price: {
          type: 'text-price-field',
          label: 'قیمت با تخفیف',
          rules: [Constants.rules.numeric],
          required: true,
          suffix: 'تومان',
        },
        end_date_offer: {
          type: 'ModalDatePicker',
          label: 'تاریخ پایان تخفیف',
          min: moment(new Date()).format('YYYY/MM/DD'),
          required: true,
        },
        is_permanent_offer: {
          type: 'checkbox',
          label: 'تخفیف دائمی می باشد',
          class: 'pa-2 align-center pt-7',
          required: true,
          col: { cols: 12, md: 4 },
        },
        status: {
          type: 'CustomInput',
          label: 'وضعیت انتشار',
          inputType: 'select',
          items: [
            { id: '0', value: 'در حال بررسی' },
            { id: '1', value: 'تایید شده' },
            { id: '2', value: 'رد شده' },
          ],
          itemValue: 'id',
          itemText: 'value',
          rules: [Constants.rules.required],
          required: true,
        },
        status_desc: {
          type: 'CustomInput',
          inputType: 'text',
          solo: true,
          flat: true,
          label: 'توضیحات دلیل رد شدن فایل',
          backgroundColor: 'grey lighten-2',
          class: 'required rounded-lg',
        },
      },
      linksSchema: {
        media_id: {
          type: 'MyFilePond',
          rules: [Constants.rules.required],
          required: true,
          fileTypes: null,
          maxFileSize: '300MB',
          col: { cols: 12 },
          isRemote: true,
        },
        link: {
          type: 'CustomInput',
          label: 'لینک فایل',
          class: 'ltr',
          col: { cols: 12 },
        },
      },
    };
  },
  created() {
    this.getSellers();
    this.getFeatures();
    this.getSoftwares();
  },

  methods: {
    getData() {
      MyAxios.get('/lesson/index')
        .then(response => {
          this.lessons = response.data;
        })
        .catch(error => {
          console.log('laws', error.response);
        });
    },
    getFeatures() {
      if (!this.schema.features.loading) this.schema.features.loading = true;

      MyAxios.get('/features/index', {
        params: {
          noPaginate: true,
        },
      })
        .then(response => {
          this.schema.features.loading = false;
          this.schema.features.items = response.data;
        })
        .catch(error => {
          this.schema.features.loading = false;
        });
    },
    getSoftwares() {
      if (!this.schema.software_id.loading)
        this.schema.software_id.loading = true;

      MyAxios.get('/software/index', {
        params: {
          noPaginate: true,
        },
      })
        .then(response => {
          this.schema.software_id.loading = false;
          this.schema.software_id.items = response.data.map(val => ({
            ...val,
            name: val.name + ' (' + val.format + '.)',
          }));
        })
        .catch(error => {
          this.schema.tags.loading = false;
        });
    },
    getSellers() {
      MyAxios.get('/user/list/vendor', {
        params: {
          noPaginate: true,
          with: 'seller',
        },
      })
        .then(response => {
          console.log(response);
          this.schema.author_id = {
            ...this.schema.author_id,
            loading: false,
            items: [
              {
                id: 1,
                value: 'ادمین',
              },
              ...response.data.map(val => ({
                id: val.id,
                value:
                  val.first_name +
                  ' ' +
                  val.last_name +
                  (val.seller && val.seller?.store_name
                    ? ' (' + val.seller?.store_name + ')'
                    : ''),
              })),
            ],
          };
        })
        .catch(error => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        if (
          !this.model.links.filter(val => !!val?.media_id || val?.link?.length)
            .length
        ) {
          this.$root.$emit('toast', {
            text: 'بخش فایل ها باید حداقل دارای یک فایل باشد.',
          });
          return;
        }

        let price = this.model.price.split(',').join('');
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(',').join('')
            : null;

        if (offer_price && offer_price.length && +offer_price > +price) {
          this.$root.$emit('toast', {
            text: 'قیمت با تخفیف نباید بیشتر از قیمت اصلی باشد!',
          });
          return;
        }
        this.loading = true;
        let data = formPostPrepare(this.model);
        data.append('slug', this.model.name.replace(/\/|\s|\./g, '-'));
        data.append('price', price);

        if (offer_price) data.append('offer_price', offer_price);

        if (this.model.features.length > 0)
          data.append(
            'features',
            JSON.stringify(
              this.model.features.map(val => ({ feature_id: val }))
            )
          );

        if (this.model.tags.length > 0)
          data.append(
            'tags',
            JSON.stringify(this.model.tags.map(val => ({ tag_id: val })))
          );

        if (this.model.links.length > 0)
          data.append(
            'links',
            JSON.stringify(
              this.model.links
                .filter(val => val?.media_id || val?.link?.length)
                .map(val => {
                  let is_external =
                    typeof val.link == 'string' && val?.link?.length;
                  return {
                    is_external: !!is_external || undefined,
                    media_id: is_external
                      ? undefined
                      : val.media_id?.id || val.media_id,
                    link: is_external ? val.link : undefined,
                  };
                })
            )
          );

        data.append('is_permanent_offer', this.model.is_permanent_offer? 1:0);

        MyAxios.post('products/create', data)
          .then(response => {
            this.submitVendor(response.data.id);
          })
          .catch(error => {
            this.loading = false;
            if (parseInt(error.response.status) === 421) {
              this.$root.$emit('toast', {
                text: 'اسلاگ وارد شده تکراری است. لطفا نام فایل را تغییر دهید.',
              });
            } else
              this.$root.$emit('toast', {
                text: 'مشکلی پیش آمده است. لطفا دوباره سعی کنید',
              });
            console.log(error);
          });
      } else {
        this.$root.$emit('toast', {
          text: 'لطفا اطلاعات را به درستی وارد نمایید.',
        });
      }
    },
    submitVendor(product_id) {
      if (product_id) {
        let price = this.model.price.split(',').join('');
        let offer_price =
          this.model.offer_price && this.model.offer_price.length
            ? this.model.offer_price.split(',').join('')
            : null;

        let data = new FormData();
        data.append('user_id', this.model.author_id);
        data.append('product_id', product_id);
        data.append(
          'price',
          offer_price && offer_price.length ? offer_price : price
        );

        MyAxios.post('vendors/create', data)
          .then(response => {
            this.loading = false;
            this.$root.$emit('toast', {
              text: 'فایل با موفقیت ایجاد شد.',
              type: 'success',
            });
            this.$router.replace({
              path: this.destinationRoute,
              query: this.$route.query || {},
            });
          })
          .catch(error => {
            this.loading = false;

            if (parseInt(error.response?.status ?? 0) === 500) {
              this.$root.$emit('toast', {
                text:
                  'اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است',
              });
            } else {
              this.$root.$emit('toast', {
                text: 'مشکلی پیش آمده است. لطفا دوباره تلاش کنید.',
              });
            }
          });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
